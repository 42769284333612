import React, { useContext} from 'react'
import { MainContext } from "context/context"

import Video from './modalTypes/video'
import YouTube from './modalTypes/youtube'
import ContactConfirm from './modalTypes/contactConfirm'
import ShareSuccess from './modalTypes/shareSuccess'
import RegisterProductFromPortal from './modalTypes/registerProductFromPortal'
import PortalConfirm from './modalTypes/portalConfirm'
import TeamMember from './modalTypes/teamMember'

export default ({
    location,
}) => {
	const {
        modalData,
        closeModal
    } = useContext(MainContext)

    const {
        type,
        data,
        isVisible
    } = modalData

    let component = null

    const commonProps = {
        data,
        isVisible,
        close: closeModal,
        location,
    }

    switch(type){
        case 'VIDEO':
            component = (
                <Video
                    { ...commonProps }
                />
            )
            break
        case 'YOUTUBE':
            component = (
                <YouTube
                    { ...commonProps }
                />
            )
            break
        case 'CONTACT_CONFIRM':
            component = (
                <ContactConfirm
                    {...commonProps}
                />
            )
            break
        case 'PORTAL_CONFIRM':
            component = (
                <PortalConfirm
                    {...commonProps}
                />
            )
            break
        case 'SHARE_SUCCESS':
            component = (
                <ShareSuccess
                    {...commonProps}
                />
            )
            break
        case 'REGISTER_PRODUCT_FROM_PORTAL':
            component = (
                <RegisterProductFromPortal
                    {...commonProps}
                />
            )
            break
        case 'TEAM_MEMBER':
            component = (
                <TeamMember
                    {...commonProps}
                />
            )
            break
    }

    return (
        <div 
            className={`modal ${ isVisible ? 'visible' : '' }`}
            onClick={closeModal}
        >
            <div 
                className={type}
                onClick={e => e.stopPropagation()}
            >
                { component }
            </div>
        </div>
    )
}