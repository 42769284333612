import React from 'react'

import TextInput from "components/textInput"
import TextArea from "components/textArea"
import Checkboxes from 'components/checkboxes'
import Radiobuttons from 'components/radiobuttons'
import Select from 'components/formFields/selectBox'
import FileUpload from 'components/formFields/fileUpload'
import ProductRepeater from 'components/formFields/productRepeater'

export default ({
    column,
    values,
    handleChange,
    error,
    qrCodeData,
    currentUser,
    activeProduct,
    warranty,
}) => {
    let Component = null
    let className = ''

    switch (column.type) {
        case 'text':
        case 'email':
        case 'password':
            Component = TextInput
            break;
        case 'dropdown':
        case 'select':
            className = 'select'
            Component = Select
            break;
        case 'textarea':
            Component = TextArea
            break
        case 'checkboxes':
            Component = Checkboxes
            break
        case 'radioboxes':
            Component = Radiobuttons
            break
        case 'file':
            Component = FileUpload
            break
        case 'productRepeater':
            Component = ProductRepeater
            break
    }

    const inputKey = column.label

    const ownedProducts = currentUser ? JSON.parse(currentUser.ownedProducts_) : []

    let productsSerials = ''
    let productsPurchaseDates = ''

    if ( ownedProducts ) {
        ownedProducts.map((row) => {
            if ( activeProduct.databaseId === 603 ) {
                if (row.product.ID === 603) {
                    productsSerials += 'FLAIM Trainer - ' + row.serial + '\r\n'
                    productsPurchaseDates += 'FLAIM Trainer - ' + row.serial + ' - ' + row.dateRegistered + '\r\n'
                }
            } else {
                if (row.product.ID === 604) {
                    productsSerials += 'FLAIM Extinguisher - ' + row.serial + '\r\n'
                    productsPurchaseDates += 'FLAIM Extinguisher - ' + row.serial + ' - ' + row.dateRegistered + '\r\n'
                }
            }
        })

        productsSerials = productsSerials.trim()
        productsPurchaseDates = productsPurchaseDates.trim()
    }

    const options = (() => {
        let _options = column.options
        
        if(warranty && ownedProducts){
            switch(column.label){
                case 'Serial Number':
                    _options = productsSerials
                    break
                case 'Date of purchase':
                    _options = productsPurchaseDates
                    break
            }
        }

        return _options
    })()

    if(!Component){
        return null 
    }

    return (
        <div className={error ? 'has-error' : ''}>
            { ( warranty && ownedProducts && column.label === 'Product' ) ?
                <></>
            :
                <Component 
                    label={column.label} 
                    options={options} 
                    inputKey={inputKey}
                    className={className}
                    value={values[inputKey]}
                    handleChange={value => handleChange(inputKey, value)}
                    type={column.type}
                    qrCodeData={qrCodeData}
                    currentUser={currentUser}
                />
            }
        </div>
    )
}