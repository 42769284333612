import React, { useContext } from 'react'
import { MainContext } from "context/context"

export default ({
    data,
    close,
    isVisible
}) => {
    const {
        options: {
            socialLinks,
        }
    } = useContext(MainContext)

    let _title = ''
    let _copy = ''

    if(data && data.title && data.body){
        _title = data.title
        _copy = data.body
    }
    
    return (
        <div>
            <button 
                className="close"
                onClick={close}
            >
                <i className="fe fe-x" />
            </button>
            <div>
                <h2>
                    { _title }
                </h2>
                <div className="copy" dangerouslySetInnerHTML={{ __html: _copy }} />
                <button className="button close-button" onClick={close}>CLOSE</button>
                <div className="modal-social">
                    <p dangerouslySetInnerHTML={{ __html: 'Join the FLAIM community!' }} />
                    <ul>
                        { socialLinks.map(link => (
                            <li key={link.url}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer">
                                    <i className={`fa fa-${ link.type }`} />
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}