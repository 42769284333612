import React from 'react'
import FormColumn from "./formColumn"

export default ({
    section,
    handleChange,
    values,
    errors,
    qrCodeData,
    currentUser,
    activeProduct,
    warranty,
}) => {
    return (
        <div>
            {section.rowsHeading ?
                <h1>
                    {section.rowsHeading}
                </h1>
                : null}
            {section.copy ?
                <p>
                    {section.copy}
                </p>
                : null}
            <ul>
                {section.rows.map((row, index) => {
                    if (!row.columns) {
                        return
                    }

                    return (
                        <li className={`row`} key={index}>
                            {(row.heading && row.heading !== 'Which product are you interested in?') ?
                                <h4>
                                    {row.heading}
                                </h4>
                                : null}
                            <ul className={`columns columns-${row.columns.length}`}>
                                {row.columns.map((column, columnIndex) => {
                                    return (
                                        <li key={columnIndex}>
                                            <FormColumn
                                                column={column}
                                                handleChange={handleChange}
                                                values={values}
                                                error={errors[column.label]}
                                                qrCodeData={qrCodeData}
                                                currentUser={currentUser}
                                                activeProduct={activeProduct}
                                                warranty={warranty}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}