import React, { useEffect, useState, useContext } from 'react'
import { MainContext } from "context/context"

import Button from 'components/button'
import Link from 'components/link'

import Cookies from 'js-cookie'

export default ({
    data
}) => {
    const {
        title,
        copy
    } = data || {}
	
	const {
		options
    } = useContext(MainContext)

    const [isVisible, setIsVisible] = useState(false)

    const _window = typeof window !== 'undefined' ? window : null

    useEffect(() => {
        if(_window){
            setTimeout(() => {
                const hasDismissed = Cookies.get('hasDismissed')

                if(!hasDismissed){
                    setIsVisible(true)
                }
            },1000)
        }
    }, [_window])

    const handleDismiss = () => {
        setIsVisible(false)

        Cookies.set('hasDismissed', 'true')
    }

    return (
        <div className={`cookies-pop-up ${ isVisible ? 'visible' : '' }`}>
            <h3>
                { title }
            </h3>
            <div className={`copy`} dangerouslySetInnerHTML={{ __html: copy }} />
            <div className="button-row">
                <Button 
                    label={'Understood'}
                    onClick={handleDismiss}
                />
                { options.cookiesPopUp.privacyPolicy ?
                    <Link to={`/privacy-policy`}>
                        View privacy policy.
                    </Link>
                : '' }
            </div>
        </div>
    )
}