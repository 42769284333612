import React, { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'

import Button from 'components/button'

import TextInput from "components/textInput"
import Select from 'components/formFields/selectBox'

export default ({
    handleChange: _handleChange,
    value: rows,
}) => {
    const rowTemplate = {
        product: null,
        serial: ''
    }

    const handleChange = (index, type, value) => {
        const _rows = cloneDeep(rows)

        _rows[index][type] = value

        _handleChange(_rows)
    }

    const handleAddRow = e => {
        e.preventDefault()
        e.stopPropagation()

        const _rows = [...rows]
        _rows.push(rowTemplate)

        _handleChange(_rows)
    }

    const deleteRow = (e, index) => {
        e.preventDefault()
        e.stopPropagation()

        const _rows = [...rows]
        _rows.splice(index, 1)

        _handleChange(_rows)
    }

    useEffect(() => {
        if(!rows || !rows.length){
            _handleChange([rowTemplate])
        }
    }, [rows])

    return (
        <div className={'product-repeater'}>
            <ul>
                {rows && rows.map((row, index) => {
                    return (
                        <div className={'row'} key={index}>
                            <Select
                                label={'Product'}
                                options={[
                                    {
                                        value: 604,
                                        label: 'Extinguisher'
                                    },{
                                        value: 603,
                                        label: 'Trainer'
                                    }
                                ]}
                                handleChange={value => handleChange(index, 'product', value)}
                                value={row.product || undefined}
                            />
                            <TextInput
                                inputKey={'Serial number'}
                                type={'text'}
                                handleChange={value => handleChange(index, 'serial', value)}
                                value={row.serial || ''}
                            />
                            <button onClick={e => deleteRow(e, index)}>
                                <i className={'fe fe-trash'} />
                            </button>
                        </div>
                    )
                })}
            </ul>
            <Button
                label={'Add another'}
                onClick={handleAddRow}
                className={'add-row'}
                compact
            />
        </div>
    )
}