import React from 'react'

export default ({column, inputKey, handleChange}) => {
    return (
        <>
            <div className="textarea-label">{inputKey}</div>
            <textarea 
                onChange={e => handleChange(e.target.value)}
                placeholder={inputKey}
            />
        </>
    )
}