import React from 'react'

export default ({
    data,
    close,
}) => {
    const {
        title,
        position,
        bio,
        image,
        firefighter,
        defence,
    } = data
    
    return (
        <div>
            <button 
                className="close"
                onClick={close}
            >
                <i className="fe fe-x" />
            </button>
            <div className="TEAM_MEMBER-cont">
                <div className={`TEAM_MEMBER-image ${ firefighter ? 'TEAM_MEMBER-image-firefighter' : '' } ${ defence ? 'TEAM_MEMBER-image-defence' : '' }`}>
                    <div style={{ backgroundImage: `url(${ image })` }} />
                </div>
                <div className="TEAM_MEMBER-details">
                    <div className="TEAM_MEMBER-name" dangerouslySetInnerHTML={{ __html: title }} />
                    <div className="TEAM_MEMBER-position" dangerouslySetInnerHTML={{ __html: position }} />
                    <div className="TEAM_MEMBER-bio" dangerouslySetInnerHTML={{ __html: bio }} />
                </div>
            </div>
        </div>
    )
}
