import React, { useContext } from 'react'
import { MainContext } from 'context/context'

export default ({
    data,
    close,
    isVisible
}) => { 
    const {
        options: {
            contactFormConfirmationCopy: {
                title,
                copy
            }
        }
    } = useContext(MainContext)

    let _title = title
    let _copy = copy

    if(data && data.title && data.body){
        _title = data.title
        _copy = data.body
    }
    
    return (
        <div>
            <button 
                className="close"
                onClick={close}
            >
                <i className="fe fe-x" />
            </button>
            <div>
                <h2>
                    { _title }
                </h2>
                <div className="copy" dangerouslySetInnerHTML={{ __html: _copy }} />
            </div>
        </div>
    )
}