export const isLoggedIn = () => {
  const user = typeof window !== 'undefined' && window.localStorage.getItem('user')

  return !!user
}

export const getCurrentUser = () => {
  const user = typeof window !== 'undefined' && window.localStorage.getItem('user')

  if(user){
    return JSON.parse(user)
  }

  return null
}

export const logout = () => {
  if(typeof window !== 'undefined'){
    window.localStorage.removeItem('user')
  }
}