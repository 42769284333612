import React, { useState } from 'react'
import Menu from 'components/menu'

import ContactBackground from '../images/contact-background.png'

export default ({
    menuItems,
    location,
    buttons,
    isOpen,
    close,
}) => {
    const [openMenuItem, setOpenMenuItem] = useState(null)

    const handleToggleMenu = index => {
        setOpenMenuItem(openMenuItem === index ? null : index)
    }

    return (
        <menu className={`mobile-menu ${ isOpen ? 'open' : '' }`}>
            <div className="inner">
                <Menu
                    menuItems={menuItems}
                    handleOpenParent={handleToggleMenu}
                    location={location}
                    openMenuItem={openMenuItem}
                    onClick={close}
                />
                { buttons }
            </div>
            <img src={ContactBackground} className="bg" />
        </menu>
    )
}