import React, { useContext } from 'react'

import Header from './header'
import Footer from './footer'
import Modal from './modal'
import CookiesPopUp from './cookiesPopUp'

import { MainContext } from "context/context"

export default ({ children, location }) => {
	const {
        options: {
            cookiesPopUp
        }
	} = useContext(MainContext)
	
	return (
		<div className="page-container">
			<Header location={location} />
			<main>
				{ children }
			</main>
			<Footer />
			<Modal location={location} />
			<CookiesPopUp data={cookiesPopUp} />
		</div>
	)
}
