import React, { useContext, useState, useEffect } from "react"
import { MainContext } from "../context/context"
import FormTab from "components/formTab"
import Loader from 'components/loader'
import { cloneDeep } from 'lodash'
import FadeCollapse from 'src/components/fadeCollapse'
import { isEqual } from 'lodash'
import Link from 'components/link'

import { emailIsValid } from 'utils'

import countryCodes from 'utils/countryCodes'

import axios from 'axios'

export default ({
    form,
    handleSubmit,
    isLoading,
    error,
    type,
    fieldValues: parentFieldValues,
    setFieldValues: parentSetFieldValues,
    currentUser,
    activeProduct,
    warranty,
    location,
}) => {
    const [childFieldValues, childSetFieldValues] = useState({})
    const [errors, setErrors] = useState({})
    const [checkboxChecked, setCheckboxChecked] = useState(false)

    const fieldValues = parentFieldValues || childFieldValues
    const setFieldValues = parentSetFieldValues || childSetFieldValues

    const activeSection = form ? form.formFields.sections[0] : null

    const {
        buttonLabel,
        checkboxConfirmCopy,
        sections,
        showForgotPassword
    } = form.formFields

    useEffect(() => {
        let hasCountryField = false

        sections.forEach(section => {
            section.rows.forEach(row => {
                if (!row.columns) {
                    return
                }

                row.columns.forEach(column => {
                    if (column.label === 'Country') {
                        hasCountryField = true
                    }
                })
            })
        })

        if (hasCountryField) {
            axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=462f5e9b54af4b389288c77531d74905`)
                .then(({ data }) => {
                    const { country_name } = data

                    handleChange('Country', {
                        label: country_name,
                        value: country_name
                    })
                }).catch(error => {
                    console.log(error)
                })
        }

        if (location) {
            const {
                search
            } = location

            if (search) {
                const params = search.replace('?', '').split('&')

                const paramsObject = {}

                params.forEach(param => {
                    const parts = param.split('=')

                    paramsObject[parts[0]] = parts[1]
                })

                window.qrCodeData = paramsObject

            }

            if (window.qrCodeData) {
                const {
                    product,
                    serial
                } = window.qrCodeData

                const _product = product === 'trainer' ?
                    {
                        value: 603,
                        label: 'Trainer'
                    }
                    :
                    {
                        value: 604,
                        label: 'Extinguisher'
                    }

                setTimeout(() => {
                    handleChange('Product',
                        [
                            {
                                product: _product,
                                serial: serial
                            }
                        ]
                    )
                }, 100)
            }
        }


    }, [])

    const handleChange = (inputKey, value) => {
        const _fieldValues = cloneDeep(fieldValues)

        const phoneKey = 'Phone Number'

        if (inputKey === 'Country' && (!fieldValues[phoneKey] || fieldValues[phoneKey].length < 5)) {
            const _country = countryCodes.find(country => country.name.trim().includes(value.value.trim()))

            _fieldValues[phoneKey] = _country.dial_code
        }

        _fieldValues[inputKey] = value

        setFieldValues(_fieldValues)
    }

    const checkIfValid = () => {
        let _isValid = true
        const _errors = {}

        const optionalFields = []

        if (checkboxConfirmCopy && !checkboxChecked) {
            _isValid = false
        }

        activeSection.rows.forEach(row => {

            if (!row.columns) {
                return
            }

            const checkFields = row.columns.filter(column => !optionalFields.includes(column.label))

            checkFields.forEach(({ label }) => {
                const value = fieldValues[label]

                let isFieldValid = !!fieldValues[label]

                if (isFieldValid && Array.isArray(fieldValues[label])) {
                    fieldValues[label].forEach(row => {
                        let isRowValid = true

                        Object.keys(row).forEach(rowKey => {

                            if (!row[rowKey]) {
                                isRowValid = false
                            }
                        })

                        if (!isRowValid) {
                            isFieldValid = false
                        }
                    })
                }

                if (label === 'Which product are you interested in?') {
                    isFieldValid = true
                }

                if (!isFieldValid) {
                    _isValid = false
                } else {
                    switch (label) {
                        case 'Confirm Password':
                            if (fieldValues['Password'] !== value) {
                                _errors[label] = true
                            }
                            break
                        case 'Email Address':
                        case 'Email':
                            if (!emailIsValid(value)) {
                                _errors[label] = true
                            }
                            break
                    }
                }
            })
        })

        if (!isEqual(_errors, errors)) {
            setErrors(_errors)
        }

        return _isValid
    }

    const isValid = checkIfValid()

    return (
        <>
            {activeSection ?
                <form
                    className="form-container"
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(fieldValues)
                    }}
                >
                    <Loader visible={isLoading} />
                    <FadeCollapse open={!!error}>
                        <div className="error">
                            <i className="fa fa-exclamation-triangle" />
                            {error}
                        </div>
                    </FadeCollapse>
                    <div className="form-tabs">
                        <div className={"tab"}>
                            <FormTab
                                section={activeSection}
                                handleChange={handleChange}
                                values={fieldValues}
                                errors={errors}
                                qrCodeData={typeof window !== 'undefined' ? window.qrCodeData : null}
                                currentUser={currentUser}
                                activeProduct={activeProduct}
                                warranty={warranty}
                            />
                        </div>
                        {checkboxConfirmCopy ?
                            <label className={`checkbox-confirm-copy ${checkboxChecked ? 'checked' : ''}`}>
                                <div className={'checkbox'}>
                                    <input
                                        type={'checkbox'}
                                        checked={checkboxChecked}
                                        onChange={() => setCheckboxChecked(!checkboxChecked)}
                                    />
                                    <span />
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: checkboxConfirmCopy }} className="copy" />
                            </label>
                            :
                            showForgotPassword ?
                                <label className={'forgot-password'}>
                                    <Link to={type === 'FORGOT_PASSWORD' ? '/login' : '/forgot-password'}>
                                        {type === 'FORGOT_PASSWORD' ? 'Back to login' : 'Forgot your password?'}
                                    </Link>
                                </label>
                                : null}
                        <button
                            type="submit"
                            className='button outline'
                            disabled={!isValid}
                        >
                            {buttonLabel || 'Submit'}
                        </button>
                    </div>
                </form>
                : null
            }
        </>
    )
}