import React from 'react'

export default ({
    inputKey, 
    handleChange,
    value,
    type, 
    currentUser
}) => {
    const _type = type === 'password' ? 'password' : 'text'

    let canEdit = true
    let _value = value

    if ( currentUser ) {
        if (inputKey === 'First Name') {
            _value = currentUser.firstName
            canEdit = false
        }
        if (inputKey === 'Last Name') {
            _value = currentUser.lastName
            canEdit = false
        }
        if (inputKey === 'Email address') {
            _value = currentUser.email
            canEdit = false
        }
        if (inputKey === 'Phone') {
            _value = currentUser.phone
            canEdit = false
        }
    }
    return (
        <input
            type={_type}
            className={type}
            placeholder={inputKey}
            onChange={e => handleChange(canEdit ? e.target.value : _value)}
            value={_value}
        />
    )
}