import React from 'react'
import axios from 'axios'

export default ({
    handleChange,
}) => {
    const ajaxUrl = `https://admin.flaimsystems.com/wp-admin/admin-ajax.php`

    const uploadToWordPress = e => {
        const formData = new FormData()
        formData.append('action', 'upload')
        formData.append('file', e.target.files[0])

        axios.post(ajaxUrl, formData)
            .then(response => {
                handleChange(response.data)
            }).catch(error => {
                console.log(error)
            })
    }

    return (
        <input
            type="file"
            multiple
            onChange={uploadToWordPress}
        />
    )
}