// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-portal-js": () => import("./../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-enter-new-password-js": () => import("./../src/templates/enterNewPassword.js" /* webpackChunkName: "component---src-templates-enter-new-password-js" */),
  "component---src-templates-environments-single-js": () => import("./../src/templates/environmentsSingle.js" /* webpackChunkName: "component---src-templates-environments-single-js" */),
  "component---src-templates-forgot-password-js": () => import("./../src/templates/forgotPassword.js" /* webpackChunkName: "component---src-templates-forgot-password-js" */),
  "component---src-templates-login-js": () => import("./../src/templates/login.js" /* webpackChunkName: "component---src-templates-login-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-register-js": () => import("./../src/templates/register.js" /* webpackChunkName: "component---src-templates-register-js" */),
  "component---src-templates-single-js": () => import("./../src/templates/single.js" /* webpackChunkName: "component---src-templates-single-js" */),
  "component---src-templates-success-stories-single-js": () => import("./../src/templates/successStoriesSingle.js" /* webpackChunkName: "component---src-templates-success-stories-single-js" */),
  "component---src-templates-text-js": () => import("./../src/templates/text.js" /* webpackChunkName: "component---src-templates-text-js" */)
}

