import React from 'react'

export default ({
    visible
}) => {
    return (
        <div className={`loader-container ${ visible ? 'visible' : ''}`}>
            <div className="loader" />
        </div>
    )
}