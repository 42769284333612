import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIsMobile } from 'utils'

import { throttle } from 'lodash'

export const MainContext = React.createContext()

const Provider = ({
	children
}) => {
	const [modalData, setModalData] = useState(false)
	const [isHeaderInverted, setIsHeaderInverted] = useState(false)
	const [hasScrolled, setHasScrolled] = useState(false)
	const invertedHeaderSections = useRef([])

	const isHeaderInvertedRef = useRef(isHeaderInverted)
	isHeaderInvertedRef.current = isHeaderInverted

	const hasScrolledRef = useRef(hasScrolled)
	hasScrolledRef.current = hasScrolled

	const isMobile = useIsMobile()

	const isMobileRef = useRef(false)

	const _window = typeof window !== 'undefined' ? window : null

	useEffect(() => {
		/* if(isMobile && _window){
			const viewport = document.getElementsByName('viewport')

			const ratio = _window.screen.width / 500

			viewport[0].content = `width=500, initial-scale=${ ratio }, maximum-scale=${ ratio }`
		} */
	}, [isMobile, _window])
	
	const addInvertedHeaderSection = ref => {
		const { current } = invertedHeaderSections

		if(current.indexOf(ref) === -1){
			current.push(ref)
		}

		invertedHeaderSections.current = current

		checkInvertedHeaderOffsets()
	}

	useEffect(() => {
		let scrollHandler = null

		if(_window){
			scrollHandler = _window.addEventListener('scroll', throttledHandleScroll.current)

			isMobileRef.current = _window.innerWidth <= 500 

			_window.addEventListener('resize', () => {
				const isNowMobile = _window.innerWidth <= 500

				if(isMobileRef.current !== isNowMobile){
					window.location.reload()
				}
			})
		}

		return () => {
			if(scrollHandler){
				scrollHandler.remove()
			}
		}
	}, [_window])

	const checkInvertedHeaderOffsets = () => {
		const invertRefs = invertedHeaderSections.current
		let _isHeaderInverted = false

		if(_window){
			const scrollOffset = _window.pageYOffset

			if(scrollOffset > 10 && !hasScrolledRef.current){
				setHasScrolled(true)
			}else if(scrollOffset <= 10 && hasScrolledRef.current){
				setHasScrolled(false)
			}
		}

		invertRefs.forEach(ref => {
			const offsets = ref.getBoundingClientRect()

			const isOverSection = offsets.top < 120 && (offsets.top + offsets.height) > 120

			if(isOverSection){
				_isHeaderInverted = true
			}
		})

		if(_isHeaderInverted !== isHeaderInvertedRef){
			setIsHeaderInverted(_isHeaderInverted)
		}
	}

	const throttledHandleScroll = useRef(throttle(checkInvertedHeaderOffsets, 100))

    const openModal = props => {
        setModalData({
            ...props,
            isVisible: true
        })
	}
	
	const closeModal = () => {
        setModalData({
            ...modalData,
            isVisible: false
        })
    }

	const optionsQuery = useStaticQuery(graphql`
		query optionsQuery {
			siteFields {
				options {
					options {
						logos {
							standard {
								sourceUrl
							}
							inverted {
								sourceUrl
							}
						}
						heroBottomDivider {
							sourceUrl
						}
						dotsBackground {
							sourceUrl
						}
						arrows {
							left {
								sourceUrl
							}
							right {
								sourceUrl
							}
						}
						blogContactStrip {
							title
							button {
								title
								url
							}
							image {
								sourceUrl
							}
						}
						preFooterOrangeStrip {
							title
							button {
								title
								url
							}
						}
						footerLogos {
							logo {
								mediaItemUrl
							}
							link
						}
						socialLinks {
							type
							url
						}
						copy {
							registerPageSideCopy
							footerSocialLinksCopy
							contactFormSuccessTitle
							contactFormSuccessCopy
						}
						cookiesPopUp {
							title
							copy
							privacyPolicy {
								mediaItemUrl
							}
						}
						portalHeadingCopy
						contactFormConfirmationCopy {
							title
							copy
						}
						warrantyFormConfirmationCopy {
							title
							copy
						}
						legals {
							legalLinks {
								title
								type
								link {
									url
								}
								file {
									mediaItemUrl
								}
							}
						}
						customerSurveyQuestions {
							question
							answers {
								bad
								medium
								good
							}
						}
						customerSurveyIcons {
							bad {
								mediaItemUrl
							}
							medium {
								mediaItemUrl
							}
							good {
								mediaItemUrl
							}
						}
						headerText
						headerDocument {
							mediaItemUrl
						}
						headerLink {
							title
							url
						}
						confirmationCopy {
							registerProductFromWithinPortal
							afterSharingOnSocials
							afterMakingWarrantyClaim
						}
						findSupplierInstructions
						environmentsCtas {
							title
							copy
							link {
								title
								url
							}
						}
					}
				}
			}
		}
	`)
	
	const { options } = optionsQuery.siteFields.options

	return (
		<MainContext.Provider 
			value={{
				options,
				openModal,
				closeModal,
				modalData,
				addInvertedHeaderSection,
				checkInvertedHeaderOffsets,
				isHeaderInverted,
				hasScrolled,
			}}
		>
			{ children }
		</MainContext.Provider>
	)
};

export default ({ element }) => (
	<Provider>
		{element}
	</Provider>
);