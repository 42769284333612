import React, { useContext, useState, useEffect } from 'react'
import Form from 'components/form'
import { GET_FORM, REGISTER_PRODUCT_FROM_PORTAL } from '../../graphql/queries'
import { useLazyQuery, useMutation } from '@apollo/client'
import { MainContext } from "../../context/context"

export default ({
    data,
    close,
    location,
}) => { 

    const [getForm, { 
        data: formData,
        error,
        loading
    }] = useLazyQuery(GET_FORM)

    const {
        openModal,
        options
    } = useContext(MainContext)

    const [fieldValues, setFieldValues] = useState({})

    useEffect(() => {
        getForm({
            variables: {
                id: 'Zm9ybToyNjI0'
            },
        })
    }, [getForm])


    const [registerProductFromPortal] = useMutation(REGISTER_PRODUCT_FROM_PORTAL)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = submitData => {
        const json = JSON.stringify(submitData)
        setIsLoading(true)

        registerProductFromPortal({
            variables: {
                data: json
            },
        }).then(response => {
            setIsLoading(false)

            data.callback()

            openModal({
                type: 'PORTAL_CONFIRM',
                data: {
                    title: 'Thank you!',
                    body: options.confirmationCopy.registerProductFromWithinPortal
                }
            })
        }).catch(error => {
            console.log(error)

            setIsLoading(false)
        })
    }

    useEffect(() => {
        console.log(data)

        if (data) {
            const fieldVals = {
                'User ID': data.userID
            }
            setFieldValues(fieldVals)
        }
    }, [])

    return (
        <div>
            <button 
                className="close"
                onClick={close}
            >
                <i className="fe fe-x" />
            </button>
            <div>
                
                { loading &&
                    <div className="modal-loading" dangerouslySetInnerHTML={{ __html: 'Loading...' }} />
                }
                { formData &&
                    <Form 
                        form={formData.form} 
                        id="Zm9ybToyNjI0"
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                        fieldValues={fieldValues}
                        setFieldValues={setFieldValues}
                        location={location}
                    />
                }

            </div>
        </div>
    )
}