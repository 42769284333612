import React, { useRef, useEffect, } from 'react'

export default ({
    data: id,
    close,
    isVisible
}) => {
    const videoPlayerRef = useRef(null)

    useEffect(() => {
        if(!isVisible && videoPlayerRef.current){
            videoPlayerRef.current.pause()
        }
    }, [isVisible])

    return (
        <div>
            <button 
                className="close"
                onClick={close}
            >
                <i className="fe fe-x" />
            </button>
            <iframe width="1000" height="563" src={ "https://www.youtube.com/embed/" + id } frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    )
}
