import React, { useContext, useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MainContext } from "context/context"
import { isLoggedIn, logout } from "../utils/auth"
import Link from 'components/link'
import { isActiveLink } from 'utils'
import { navigate } from 'gatsby'
import Menu from 'components/menu'
import MobileMenu from 'components/mobileMenu'

import extinguisher from 'images/extinguisher.png'
import trainer from 'images/trainer.png'

import 'vendor/hamburgers.css'

import { isMobileMenu } from 'utils'

export default ({
	location
}) => {

	const {
		options,
		isHeaderInverted,
		hasScrolled,
		checkInvertedHeaderOffsets,
	} = useContext(MainContext)

	const {
		logos,
		headerText,
		headerDocument,
		headerLink,
	} = options

	useEffect(() => {
		setTimeout(() => {
			checkInvertedHeaderOffsets()
		}, 1000)
	}, [location])

	const [selectedChildItems, setSelectedChildItems] = useState([])
	const [hoveredMenuItemIndex, setHoveredMenuItemIndex] = useState(-1)
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
	const [itemMidPoint, setItemMidPoint] = useState(0)
	
	const menuRefs = useRef({})
	const subMenuRef = useRef()

	let headerClassName = 'header'

	if (isHeaderInverted || isMobileMenuOpen) {
		headerClassName += ' inverted'
	}

	if (hasScrolled && !isMobileMenuOpen) {
		headerClassName += ' has-scrolled'
	}

	const { nodes: menuItems } = useStaticQuery(graphql`
        query {
            siteFields {
                menu (
					id: "TWVudTo0"
				) {
					menuItems {
						nodes {
							label
							title
							url
							childItems {
								nodes {
									label
									title
									url	
								}
							}
						}
					}
				}
            }
        }
	`).siteFields.menu.menuItems

	const handleHover = (index, items = []) => {
		if (items.length) {
			const bounding = menuRefs.current[index].getBoundingClientRect()

			setTimeout(() => {
				const subMenuWidth = subMenuRef.current.offsetWidth
				setItemMidPoint((bounding.left + (bounding.width / 2)) - ( subMenuWidth / 2))
			}, 1)

			setSelectedChildItems(items)
			setHoveredMenuItemIndex(index)
		} else {
			setHoveredMenuItemIndex(-1)
		}
	}

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen)
	}

	let headerContainerName = 'header-container'

	const _isLoggedIn = isLoggedIn()

	if (_isLoggedIn) {
		headerContainerName += ' logged-in'
	}

	if (location.pathname === '/portal' || location.pathname === '/portal/') {
		headerContainerName += ' portal'
	}

	if (location.pathname === '/case-studies' || location.pathname === '/case-studies/') {
		headerContainerName += ' success-stories'
	}

	if (location.pathname?.includes('/environments/')) {
		headerContainerName += ' environments'
	}

	const handleLogout = _ => {
		logout()
		navigate('/login')
	}

	const closeMobileMenu = () => {
		if (isMobileMenuOpen) {
			setIsMobileMenuOpen(false)
		}
	}

	const buttons = (
		<ul className="buttons">
			{_isLoggedIn ?
				<>
					<li className="register">
						<Link
							to={'/portal'}
							className={'button low'}
							onClick={closeMobileMenu}
						>
							My account
						</Link>
					</li>
					<li className="logout">
						<a
							className="button"
							onClick={() => {
								closeMobileMenu()
								handleLogout()
							}}
						>
							Logout
						</a>
					</li>
				</>
				:
				<>
					<li className="register">
						<Link
							to={'/register'}
							className={'button low'}
							onClick={closeMobileMenu}
						>
							Register
						</Link>
					</li>
					<li className="login">
						<Link
							className="button"
							to={"/login"}
							onClick={closeMobileMenu}
						>
							Login
						</Link>
					</li>
				</>
			}
		</ul>
	)

	return (
		<div
			className={headerContainerName}
			onMouseLeave={() => handleHover(-1)}
		>
			{(headerText && (headerDocument || headerLink)) ?
				<div className={'covid-strip'}>
					<div className={'container'}>
						<a href={headerDocument ? headerDocument?.mediaItemUrl : headerLink?.url} target="_blank">
							<i className={'fa fa-exclamation-triangle'} />
							<span>
								{headerText}
							</span>
						</a>
					</div>
				</div>
				: null}
			<header className={headerClassName}>
				<div className="container">
					<Link
						to={'/'}
						className={'logo'}
					>
						<img className="normal" src={logos.standard.sourceUrl} />
						<img className="inverted" src={logos.inverted.sourceUrl} />
					</Link>
					<p>
						Welcome to your Flaim Portal
					</p>
					{!isMobileMenu ?
						<>
							<Menu
								menuItems={menuItems}
								handleOpenParent={handleHover}
								location={location}
								hoveredMenuItemIndex={hoveredMenuItemIndex}
								menuRefs={menuRefs}
							/>
							{buttons}
						</>
						:
						<button
							className={`hamburger hamburger--collapse ${isMobileMenuOpen ? 'is-active' : ''}`}
							onClick={toggleMobileMenu}
						>
							<span className="hamburger-box">
								<span className="hamburger-inner" />
							</span>
						</button>
					}
				</div>
			</header>
			<div className={`header-sub-menu ${hoveredMenuItemIndex !== -1 ? 'visible' : ''}`}>
				<ul ref={subMenuRef} style={{ marginLeft: itemMidPoint + 50}}>
					{selectedChildItems.map((item, index) => {
						const {
							label,
							url
						} = item

						const icons = [trainer, extinguisher]

						return (
							<li key={index}>
								<Link className={isActiveLink(location, url) ? 'active' : ''} to={url}>
									<div className="icon">
										<img src={icons[index]} alt="" />
									</div>
									<div className="title">
										<span dangerouslySetInnerHTML={{__html: label}}/>
										<span dangerouslySetInnerHTML={{__html: `${index === 0 ? 'Trainer' : 'Extinguisher'}`}}/>
									</div>
								</Link>
							</li>
						)
					})}
				</ul>
			</div>
			{isMobileMenu &&
				<MobileMenu
					menuItems={menuItems}
					location={location}
					buttons={buttons}
					isOpen={isMobileMenuOpen}
					close={() => setIsMobileMenuOpen(false)}
				/>
			}
		</div>
	)
}
