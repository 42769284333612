import React, { useRef, useEffect, } from 'react'

export default ({
    data: source,
    close,
    isVisible
}) => {
    const videoPlayerRef = useRef(null)

    useEffect(() => {
        if(!isVisible && videoPlayerRef.current){
            videoPlayerRef.current.pause()
        }
    }, [isVisible])

    return (
        <div>
            <button 
                className="close"
                onClick={close}
            >
                <i className="fe fe-x" />
            </button>
            <video 
                ref={ref => videoPlayerRef.current = ref} 
                controls
                key={source}
                autoPlay
            >
                <source src={source} type='video/mp4;' />
            </video>
        </div>
    )
}
