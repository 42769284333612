import React, { useContext, useState, useEffect } from 'react'
import Form from 'components/form'
import { GET_FORM, SUBMIT_FORM } from '../../graphql/queries'
import { useLazyQuery, useMutation } from '@apollo/client'
import { MainContext } from "../../context/context"

export default ({
    data,
    close,
}) => { 

    const [getForm, { 
        data: formData,
        error,
        loading
    }] = useLazyQuery(GET_FORM)

    const {
        openModal,
        options
    } = useContext(MainContext)

    const [fieldValues, setFieldValues] = useState({})

    useEffect(() => {
        getForm({
            variables: {
                id: 'Zm9ybToyNjEz'
            },
        })
    }, [getForm])


    const [submitForm, { submitLoading, data: submitFormData, submitError }] = useMutation(SUBMIT_FORM)
    const [isLoading, setIsLoading] = useState(false)
    const [hasMounted, setHasMounted] = useState(false)
    

    const handleSubmit = data => {
        const json = JSON.stringify(data)
        setIsLoading(true)

        submitForm({
            variables: {
                type: 'Share Success',
                data: json
            },
        }).then(response => {
            setIsLoading(false)

            openModal({
                type: 'PORTAL_CONFIRM',
                data: {
                    title: 'Thank you!',
                    body: options.confirmationCopy.afterSharingOnSocials
                }
            })
        }).catch(error => {
            console.log(error)

            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (data) {
            const fieldVals = {
                'Name': data.name,
                'Phone': data.phone,
                'Email address': data.email,
            }
            setFieldValues(fieldVals)
        }
    }, [])

    return (
        <div>
            <button 
                className="close"
                onClick={close}
            >
                <i className="fe fe-x" />
            </button>
            <div>
                
                { loading &&
                    <div className="modal-loading" dangerouslySetInnerHTML={{ __html: 'Loading...' }} />
                }
                { formData &&
                    <Form 
                        form={formData.form} 
                        id="Zm9ybToyNjEz"
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                    />
                }

            </div>
        </div>
    )
}