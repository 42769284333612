import React from "react"
import Select from "react-select"

export default ({ options, handleChange, value, label }) => {
  let selectOptions = []

  if (typeof options === "string") {
    const _opt = options
      .split(/\n/)
      .filter(Boolean)
      .map(option => option.split(' : ')[0].trim())

    _opt.map(items => {
      selectOptions.push({
        value: items,
        label: items,
      })
    })
  } else {
    selectOptions = options
  }

  return (
    <Select
      className={"select"}
      options={selectOptions}
      onChange={handleChange}
      value={value || null}
      placeholder={label}
    />
  )
}
