import gql from 'graphql-tag'
import { graphql } from "gatsby"

export const GET_POSTS_BY_CATEGORY = gql`
    query GET_POSTS_BY_CATEGORY (
        $categoryName: String
    ) {
        posts (
            where: {
                categoryName: $categoryName
            }
        ) {
            edges {
                node {
                    link
                    title
                    excerpt
                    date
                    featuredImage {
                        mediaItemUrl
                    }
                    categories {
                        nodes {
                            name
                            slug
                        }
                    }
                    tags {
                        nodes {
                            name
                            slug
                        }
                    }
                    postFields {
                        excerpt
                    }
                    resourceFields {
                        excerpt
                        fileOrLink 
                        file {
                            mediaItemUrl
                        }
                        externalLink
                    }
                }
            }
        }
    }
`

export const GET_PORTAL_DATA = gql`
    query GET_PORTAL_DATA {
        products {
            nodes {
                databaseId
                link
                title
                slug
                featuredImage {
                    mediaItemUrl
                }
                productFields {
                    portalImage {
                        mediaItemUrl
                    }
                    youtubePlaylistId
                    howToVideos {
                        title
                        video {
                            mediaItemUrl
                        }
                        image {
                            mediaItemUrl
                        }
                    }
                    shareYourFeedback {
                        copy
                        title2
                        copy2
                    }
                    surveyMonkeyUrl
                    downloads {
                        title
                        files {
                            file {
                                mediaItemUrl
                                databaseId
                            }
                            link
                            fileName
                            size
                            showSize
                            copy
                            releaseDate
                        }
                    }
                }
            }
        }
    }
`

export const GET_RESOURCES_BY_PRODUCT = gql`
    query GET_RESOURCES_BY_PRODUCT (
        $productName: String
    ) {
        resources (
            where: {
                taxQuery: {
                    taxArray: {
                        taxonomy: PRODUCTTAX
                        terms: [$productName]
                        field: SLUG
                    }
                }
            }
        ) {
            edges {
                node {
                    title
                    date
                    featuredImage {
                        mediaItemUrl
                    }
                    types {
                        nodes {
                            name
                            slug
                        }
                    }
                    resourceFields {
                        excerpt
                        fileOrLink 
                        file {
                            mediaItemUrl
                        }
                        externalLink
                    }
                }
            }
        }
    }
`

export const SUBMIT_FORM = gql`
    mutation SUBMIT_FORM (
        $data: String
        $type: String
    ) {
        submitForm (
            input: {
                type: $type
                data: $data
                clientMutationId: "uniqueId"
            }
        ) {
            success
        }
    }
`



export const REGISTER_USER = gql`
    mutation REGISTER_USER(
        $data: String
    ){
        registerUserAndProduct (
            input: {
                clientMutationId: "uniqueId",
                data: $data
            }
        ) {
            user {
                id
                userId
                name
                productsJson
                ownedProducts
                ownedProducts_
            }
        }
    }
`


export const REGISTER_PRODUCT_FROM_PORTAL = gql`
    mutation REGISTER_PRODUCT_FROM_PORTAL(
        $data: String
    ){
        registerProductFromPortal (
            input: {
                clientMutationId: "uniqueId",
                data: $data
            }
        ) {
            success
        }
    }
`

export const LOGIN_USER = gql`
    mutation LoginUser($username : String!, $password : String!) {
        login( input: {
            clientMutationId:"uniqueId"
            username: $username
            password: $password
        } ) {
            authToken
            user {
                id
                userId
                name
                products {
                    nodes {
                        title
                    }
                }
            }
        }
    }
`

export const FORGOT_PASSWORD_REQUEST = gql`
    mutation ForgotPasswordRequest(
        $email: String!
    ) {
        forgotPasswordRequest (
            input: {
                clientMutationId:"uniqueId"
                email: $email
            }
        ) {
            success
        }
    }
`

export const RESET_PASSWORD = gql`
    mutation ResetPassword (
        $userId: ID!
        $key: String!
        $password: String!
    ) {
        resetPassword (
            input: {
                userId: $userId
                key: $key
                password: $password
                clientMutationId:"uniqueId"
            }
        ) {
            success
        }
    }
`

export const GET_USER = gql`
    query GET_USER (
        $id: ID!
    ) {
        getUserAndProducts (
            id: $id
        ) {
            id
            name
            firstName
            lastName
            phone
            email
            productsJson
            ownedProducts
            ownedProducts_
            role
            warranties
        }
    }
`

export const GET_FORM = gql`
    query GET_FORM(
        $id: ID!
    ) {
        form (
            id: $id
        ) {
            formFields {
                sections {
                    title
                    rowsHeading
                    copy
                    rows {
                        columns {
                            label
                            options
                            type    
                        }
                    }
                }
            }
        }
    }
`

export const GET_RESOURCES = gql`
    query GET_RESOURECES (
        $limit: Int!,
        $terms: String
    ) {
        resources (
            limit: $limit,
            where: {
                taxQuery: {
                    taxArray: {
                        terms: $terms
                    }
                }
            }
        ) {
            edges {
                node {
                    types {
                        nodes {
                            name
                            slug
                        }
                    }
                    link
                    title
                    date
                    featuredImage {
                        mediaItemUrl
                    }  
                    resourceFields {
                        excerpt 
                        file {
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    }
`

export const GET_POSTS = gql` 
    query GET_POSTS (
        $after: String!
        $first: Int!
    ) {
        posts (
            after: $skip
            first: $limit
        ) {
            edges {
                node {
                    link
                    title
                    excerpt
                    date
                    featuredImage {
                        mediaItemUrl
                    }
                    postFields {
                        excerpt
                    }
                    resourceFields {
                        excerpt
                        fileOrLink 
                        file {
                            mediaItemUrl
                        }
                        externalLink
                    }
                    categories {
                        nodes {
                            name
                            slug
                        }
                    }
                    tags {
                        nodes {
                            name
                            slug
                        }
                    }
                }
            }
        }
    }
`


export const FormFieldsFragment = graphql`
  fragment FormFieldsFragment on WPGraphQL_Form {
    formFields {
        sections {
            title
            rowsHeading
            copy
            rows {
                heading
                columns {
                    label
                    options
                    type    
                }
            }
        }
        buttonLabel
        checkboxConfirmCopy
        showForgotPassword
    }
}
`

export const STORE_DOWNLOAD_ID = gql`
    mutation StoreDownloadId (
        $userId: String!
        $serial: String!
        $fileId: String!
    ) {
        storeDownloadId (
            input: {
                userId: $userId
                serial: $serial
                fileId: $fileId
                clientMutationId:"uniqueId"
            }
        ) {
            success
        }
    }
`

export const STORE_LATEST_DOWNLOAD = gql`
    mutation StoreLatestDownload (
        $userId: String!
        $serial: String!
        $fileName: String!
    ) {
        storeLatestDownload (
            input: {
                userId: $userId
                serial: $serial
                fileName: $fileName
                clientMutationId:"uniqueId"
            }
        ) {
            success
        }
    }
`
