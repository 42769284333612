import React from 'react'
import Link from 'components/link'
import FadeCollapse from 'src/components/fadeCollapse'

import { 
    isActiveLink,
    isMobileMenu
} from 'utils'

export default ({
    menuItems,
    handleOpenParent,
    location,
    hoveredMenuItemIndex = -1,
    openMenuItem,
    onClick,
    menuRefs,
}) => {
    return (
        <ul className="header-menu">
            { menuItems.map((item, i) => {
                const {
                    url,
                    label,
                    childItems: {
                        nodes: childItems
                    }
                } = item

                let hasActiveChild = false

                childItems.forEach(childItem => {
                    if(isActiveLink(location, childItem.url)){
                        hasActiveChild = true
                    }
                })

                return (
                    <li 
                        key={i}
                        onMouseEnter={() => {
                            if(!isMobileMenu){
                                handleOpenParent(i, childItems)
                            }
                        }}
                    >
                        { childItems.length ? 
                            <a 
                                className={(hoveredMenuItemIndex === i || hasActiveChild) ? 'active' : ''}
                                onClick={() => {
                                    if(isMobileMenu){
                                        handleOpenParent(i)
                                    }
                                }}
                                ref={menuRefs ? el => menuRefs.current[i] = el : null}
                            >
                                { label }
                                <i className="fe fe-chevron-down" />
                            </a>
                        :
                            <Link
                                to={url}
                                className={isActiveLink(location, url) ? 'active' : ''}
                                onClick={onClick || undefined}
                            >
                                { label }
                            </Link>
                        }
                        { isMobileMenu &&
                            <FadeCollapse open={openMenuItem === i}>
                                <ul className="sub-menu">
                                    { childItems.map((childItem, childIndex) => {
                                        const {
                                            url: childUrl,
                                            label: childLabel
                                        } = childItem

                                        return (
                                            <li key={childIndex}>
                                                <Link 
                                                    to={childUrl}
                                                    className={isActiveLink(location, childUrl) ? 'active' : ''}
                                                    onClick={onClick || undefined}
                                                >
                                                    { childLabel }
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </FadeCollapse>
                        }
                    </li>
                )
            })}
        </ul>
    )
}