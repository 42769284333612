import React, { useContext } from 'react'
import Button from 'components/button'
import { MainContext } from "context/context"

export default ({
    heading, 
    buttonLabel, 
    buttonUrl
}) => {
	const {
        options: {
            preFooterOrangeStrip
        }
    } = useContext(MainContext)

    const {
        title,
        button
    } = preFooterOrangeStrip

    return (
        <div className="container">
            <h2>
                { heading || title }
            </h2>
            <Button
                label={ buttonLabel || button.title }
                url={ buttonUrl || button.url }
                inverted={true}
            />
        </div>
    )
}