import React, {useContext} from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/link'
import { MainContext } from "context/context"
import Cta from 'components/cta'
import moment from 'moment'

export default () => {
    const {
		options
    } = useContext(MainContext)
    
    const {
        menus: {
            nodes: menus
        }
    } = useStaticQuery(graphql`
        query {
            siteFields {
                menus {
                    nodes {
                        id
                        menuItems {
                            nodes {
                                label
                                title
                                url
                                childItems {
                                    nodes {
                                        label
                                        title
                                        url	
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `).siteFields

    const footerMenuId = "TWVudToxMg=="

    const { menuItems: { nodes: footerMenu }} = menus.find(menu => menu.id === footerMenuId)

    const {
        logos,
        socialLinks,
        copy: {
            footerSocialLinksCopy
        },
        legals: {
            legalLinks
        },
        footerLogos,
    } = options

    return (
        <footer>
            <div className="Cta">
                <Cta/>
            </div> 
            <div className="main">
                <div className="container">
                    <div className="left">
                        <img src={logos.inverted.sourceUrl} />
                    </div>
                    <div className="right">
                        <ul className="menu">
                            {footerMenu.map((menuItem, columnIndex) => {
                                return (
                                    <li key={columnIndex} className="column">
                                        <h4>
                                            {menuItem.label}
                                        </h4>
                                        <ul className="sub-menu">
                                            {menuItem.childItems.nodes.map((subItem, rowIndex) => {
                                                return (
                                                    <li key={rowIndex}>
                                                        <Link to={subItem.url}>
                                                            {subItem.label}
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                )
                            })}
                        </ul>
                        <div className="column">
                            <p>
                                { footerSocialLinksCopy }
                            </p>
                            <div className="social-row">
                                <ul>
                                    { socialLinks.map(link => (
                                        <li key={link.url}>
                                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                                                <i className={`fa fa-${ link.type }`} />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {footerLogos &&
                                <div className="footer-logos">
                                    { footerLogos.map((footerLogo, index) => {
                                        const {
                                            logo,
                                            link,
                                        } = footerLogo

                                        if (!logo) return false

                                        return (
                                            <div key={index}>
                                                { link ?
                                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                                        <img src={logo.mediaItemUrl} />
                                                    </a>
                                                :
                                                    <img src={logo.mediaItemUrl} />
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-strip">
                <span className='attribution'>
                    Website by <a href="https://yourcreative.com.au" target="_blank">Your Creative</a>
                </span>
                <div className="container">
                    <span className="bold">
                        &copy; Copyright FLAIM { moment().format('YYYY')}.
                    </span>
                    <ul>
                        {legalLinks.map((link, index) => {
                            return (
                                <li key={index}>
                                    { link.type === 'file' ?
                                        <a href={ link.file.mediaItemUrl } target="_blank" dangerouslySetInnerHTML={{ __html: link.title }} />
                                    :
                                        <Link to={ link.link.url } dangerouslySetInnerHTML={{ __html: link.title }} />
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </footer>
    )
}
